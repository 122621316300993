<template>
  <div class="home">
    <h3 class="common-title">设施列表</h3>
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="searchName" placeholder="请输入设施名称" clearable
                  @keyup.enter="getMerchantList"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getMerchantList">
          检索
        </el-button>
      </div>
      <div class="fr">
        <el-button type="primary" @click="newMerchant">追加</el-button>
      </div>
    </div>
    <div class="home-left">
      <Empty v-if="merchantList.length === 0"></Empty>
      <el-table
        :data="merchantList"
        class="home-list"
        style="width: 100%"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="设施名称">
        </el-table-column>
        <el-table-column label="QRCode" width="100px">
          <template #default="scope">
            <el-popover
              placement="top-start"
              title="QRCode"
              :width="200"
              trigger="hover"
              v-if="scope.row.qrcode_path">
              <el-image
                slot="reference"
                style="width: 100%;"
                :src="`/api/admin/static/qrcode/${scope.row.id}/${scope.row.qrcode_path}`"
                fit="contain">
              </el-image>
              <!--              <img slot="reference" :src="`/static/qrcode/${scope.row.id}/${scope.row.qrcode_path}`" alt="Image">-->
              <template #reference>
                <el-icon>
                  <Picture/>
                </el-icon>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_active"
          label="状态"
          width="100px">
          <template #default="scope">
            <el-icon v-if="scope.row.is_active" color="green">
              <CircleCheckFilled/>
            </el-icon>
            <el-icon v-else color="red">
              <CircleCloseFilled/>
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              class="mt5"
              type="primary"
              link
              @click="openDocumentDialog(scope.row)">
              文档管理
            </el-button>
            <el-dropdown class="ml15" split-button type="primary" @click="editMerchant(scope.row)">
              编辑
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      link
                      @click="openMultilingualDialog(scope.row)">
                      多语言设置
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      link
                      @click="openChatHistoryDialog(scope.row)">
                      历史会话
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      link
                      @click="openWelcomeListDialog(scope.row)">
                      欢迎词
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      link
                      @click="openCommonQuestionListDialog(scope.row)">
                      常见问题
                    </el-button>
                  </el-dropdown-item>

                  <el-dropdown-item divided>
                    <el-button
                      type="danger"
                      link
                      @click="deleteMerchant(scope.row)">
                      删除
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="documentDialog" title="文档管理" destroy-on-close draggable align-center>
      <div class="home-right" v-loading="loading">
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          label-width="120px"
          class="home-form"
          height="calc(100vh - 300px)"
        >
          <el-tabs
            v-model="activeName"
            type="card"
            class="document-tabs"
            @tab-click="handleClick"
          >
            <el-tab-pane label="中文" name="cn">
              <el-form-item
                label="设施文档"
                prop="content"
              >
                <el-input
                  type="textarea"
                  rows="20"
                  v-model="form.content"
                  placeholder="请输入文档">
                </el-input>
              </el-form-item>
              <el-form-item
                label="提示词"
                prop="prompt"
              >
                <el-input
                  type="textarea"
                  rows="8"
                  v-model="form.prompt"
                  :placeholder="configInfo.openai_prompt">
                </el-input>
              </el-form-item>
            </el-tab-pane>
            <div v-for="config in merchant.server_languages">
              <el-tab-pane :label="config.name" :name="config.prefix" v-if="config.is_active">
                <el-form-item
                  :label="config.name + '设施文档'"
                  :prop="'multilingual_data.' + config.prefix + '_content'"
                >
                  <el-input
                    type="textarea"
                    rows="20"
                    v-model="form.multilingual_data[config.prefix + '_content']"
                    placeholder="请输入文档">
                  </el-input>
                </el-form-item>
                <el-form-item
                  :label="config.name +'提示词'"
                  :prop="config.prefix + '_prompt'"
                >
                  <el-input
                    type="textarea"
                    rows="8"
                    v-model="form.multilingual_data[config.prefix + '_prompt']"
                    :placeholder="configInfo.multilingual_data['openai_' + config.prefix + '_prompt']">
                  </el-input>
                </el-form-item>
              </el-tab-pane>
            </div>
          </el-tabs>
          <el-form-item
            label="分片大小"
            prop="chunk_size"
          >
            <el-input-number
              v-model="form.chunk_size"
              :min="600"
              :max="3000"
              :precision="0"
              placeholder="800">
            </el-input-number>
            <el-button class="ml10" type="primary" @click="documentPreview">分片预览</el-button>
          </el-form-item>
          <el-form-item label="停用词" prop="es_stop_words">
            <el-input
              type="textarea"
              rows="10"
              v-model="form.es_stop_words"
              :placeholder=configInfo.es_stop_words
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="documentDialog = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="merchantFormRef" :title="merchantForm.id === null ? '新增':'编辑'" width="50%" destroy-on-close
               draggable align-center>
      <span v-loading="loading">
        <el-form
          ref="merchantRuleFormRef"
          :model="merchantForm"
          :rules="merchantRules"
          label-width="150px"
          height="calc(100vh - 300px)"
          class="home-form"
        >
        <el-form-item label="设施名称" prop="name">
          <el-input v-model="merchantForm.name"></el-input>
        </el-form-item>
        <div v-for="language in merchant.server_languages">
          <el-form-item :label="language.name + '设施名称'" :prop="'multilingual_data.' + language.prefix + '_name'"
                        v-if="language.is_active">
            <el-input v-model="merchantForm.multilingual_data[language.prefix + '_name']"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="副标题" prop="subtitle">
          <el-input type="textarea" rows="3" v-model="merchantForm.subtitle"></el-input>
        </el-form-item>
        <div v-for="language in merchant.server_languages">
          <el-form-item :label="language.name + '副标题'" :prop="'multilingual_data.' + language.prefix + '_subtitle'"
                        v-if="language.is_active">
            <el-input type="textarea" rows="3"
                      v-model="merchantForm.multilingual_data[language.prefix + '_subtitle']"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="设施每日访问限制" prop="daily_limit">
          <el-input-number v-model="merchantForm.daily_limit" :min="1" :step="1"></el-input-number>
        </el-form-item>
        <el-form-item label="问题字数限制" prop="query_limit">
          <el-input-number v-model="merchantForm.query_limit" :min="1" :step="1"></el-input-number>
        </el-form-item>
        <el-form-item label="设施状态" prop="is_active">
          <el-switch v-model="merchantForm.is_active" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
        <el-form-item label="流响应" prop="streaming">
          <el-switch v-model="merchantForm.streaming" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
        <el-form-item label="用户头像" prop="user_avatar">
          <el-upload
            class="avatar-uploader"
            accept="image/*"
            :auto-upload="false"
            :limit="1"
            :show-file-list="false"
            :on-change="userAvatarHandleChange"
          >
              <img v-if="merchantForm.user_avatar_url" :src="merchantForm.user_avatar_url" class="avatar"
                   alt="userAvatar" width="110" height="110"/>
              <el-icon v-else class="avatar-uploader-icon"><Plus/></el-icon>
            </el-upload>
        </el-form-item>
        <el-form-item label="助手头像" prop="assistant_avatar">
          <el-upload
            class="avatar-uploader"
            accept="image/*"
            :auto-upload="false"
            :limit="1"
            :show-file-list="false"
            :on-change="assistantAvatarHandleChange"
          >
              <img v-if="merchantForm.assistant_avatar_url" :src="merchantForm.assistant_avatar_url" class="avatar"
                   alt="assistantAvatar" width="110" height="110"/>
              <el-icon v-else class="avatar-uploader-icon"><Plus/></el-icon>
            </el-upload>
        </el-form-item>
        </el-form>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="merchantFormRef = false">取消</el-button>
          <el-button type="primary" @click="createMerchant">
            {{ merchantForm.id === null ? '新增' : '确定' }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="chatHistoryDialog" title="历史会话" width="80%" destroy-on-close align-center
               class="no-padding">
      <template #header="{ close, titleId, titleClass }">
        <div class="clearfix pb20">
          <h4 :id="titleId" :class="titleClass">历史会话</h4>
        </div>
        <div class="clearfix">
          <div class="fl pb5 pr5 pt5">
            <el-select
              v-model="chatHistoryFilter.user_id"
              placeholder="请选择用户"
              @change="handleChatUserChange"
              clearable
            >
              <el-option
                v-for="item in identifierList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="fl pb5 pr5 pt5">
            <el-input type="text" v-model="chatHistoryFilter.keyword" placeholder="请输入关键词" clearable
                      @keyup.enter="getChatHistory"></el-input>
          </div>
          <div class="fl pb5 pr5 pt5">
            <el-button
              type="primary"
              @click="getChatHistory">
              检索
            </el-button>
          </div>
          <div class="ml10 fr">
            <el-button type="success" @click="downloadChatHistory">Excel下载</el-button>
          </div>
        </div>
      </template>

      <div class="home-right" v-loading="chatHistoryInfo.loading">
        <Empty v-if="chatHistory.length === 0"></Empty>
        <el-table
          :data="chatHistory"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 600px)"
          v-else>
          <el-table-column
            prop="user.identifier"
            label="用户标识"
            min-width="200">
          </el-table-column>
          <el-table-column
            prop="query"
            label="问题"
            min-width="150">
          </el-table-column>
          <el-table-column
            prop="answer"
            label="回答"
            min-width="300">
          </el-table-column>
          <el-table-column
            prop="is_like"
            label="满意度"
            width="70">
            <template #default="scope">
              <el-icon v-if="scope.row.is_like === true" color="green">
                <i class="fa-solid fa-thumbs-up"></i>
              </el-icon>
              <el-icon v-else-if="scope.row.is_like === false" color="red">
                <i class="fa-solid fa-thumbs-down"></i>
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column
            prop="input_token"
            label="输入Token"
            width="120">
            <template #header>
              <span>输入Token</span>
              <el-tooltip
                class="box-item"
                content="仅在禁用流式响应时有值"
                placement="top"
              >
                <el-icon class="ml5">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="output_token"
            label="输出Token"
            width="120">
            <template #header>
              <span>输出Token</span>
              <el-tooltip
                class="box-item"
                content="仅在禁用流式响应时有值"
                placement="top"
              >
                <el-icon class="ml5">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="total_cost"
            label="费用(USD)"
            width="120">
            <template #header>
              <span>费用(USD)</span>
              <el-tooltip
                class="box-item"
                content="仅在禁用流式响应时有值"
                placement="top"
              >
                <el-icon class="ml5">
                  <Warning/>
                </el-icon>
              </el-tooltip>
            </template>
            <template #default="scope">
              <!-- 修改显示内容 -->
              <span v-if="scope.row.total_cost != null">{{ scope.row.total_cost }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="会话时间"
            :formatter="dateFormat"
            width="200">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="clearfix pt20 pb20">
          <el-pagination
            class="fr"
            background
            layout="prev, pager, next, sizes, total"
            :total="chatHistoryFilter.totalSize"
            @current-change="getChatHistory"
            @size-change="getChatHistory(true)"
            v-model:current-page="chatHistoryFilter.page"
            v-model:page-size="chatHistoryFilter.size"
            :page-sizes="[10, 20, 50, 100]"
          />
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="welcomeListDialog" title="欢迎信息" width="80%" destroy-on-close align-center
               class="no-padding">
      <div class="clearfix pb20">
        <div class="fr">
          <el-button type="primary" @click="newWelcome">追加</el-button>
        </div>
      </div>
      <div class="home-right" v-loading="chatHistoryInfo.loading">
        <Empty v-if="welcomeList.length === 0"></Empty>
        <el-table
          :data="welcomeList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 600px)"
          v-else>
          <el-table-column
            label="ID"
            prop="id"
            width="80">
          </el-table-column>
          <el-table-column
            prop="message"
            label="消息">
          </el-table-column>
          <el-table-column
            prop="start_date"
            label="开始日"
            width="120">
          </el-table-column>
          <el-table-column
            prop="end_date"
            label="结束日"
            width="120">
          </el-table-column>
          <el-table-column
            prop="is_default"
            label="默认"
            width="60">
            <template #default="scope">
              <el-icon v-if="scope.row.is_default" color="green">
                <CircleCheckFilled/>
              </el-icon>
              <el-icon v-else color="red">
                <CircleCloseFilled/>
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template #default="scope">
              <el-button
                type="primary"
                link
                @click="editWelcome(scope.row)">
                编辑
              </el-button>

              <el-button
                type="danger"
                link
                @click="deleteWelcome(scope.row)"
                v-if="scope.row.is_default !== true">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog v-model="welcomeFormRef" :title="welcomeForm.id === null ? '新增':'编辑'" width="30%" destroy-on-close
               draggable>
      <span v-loading="loading">
        <el-form
          ref="welcomeRuleFormRef"
          :model="welcomeForm"
          :rules="welcomeRules"
          label-width="120px"
          class="home-form"
        >
        <el-form-item label="欢迎信息" prop="message">
          <el-input type="textarea" rows="3" v-model="welcomeForm.message"></el-input>
        </el-form-item>
        <div v-for="config in merchant.server_languages">
          <el-form-item :label="config.name + '欢迎信息'" :prop="config.prefix + '_message'" v-if="config.is_active">
            <el-input type="textarea" rows="3"
                      v-model="welcomeForm.multilingual_data[config.prefix + '_message']"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="开始日期" prop="start_date">
          <el-date-picker
            v-model="welcomeForm.start_date"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            :disabled=welcomeForm.is_default>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" prop="end_date">
          <el-date-picker
            v-model="welcomeForm.end_date"
            type="date"
            placeholder="选择日期"
            value-format="YYYY-MM-DD"
            :disabled=welcomeForm.is_default>
          </el-date-picker>
        </el-form-item>
        </el-form></span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="welcomeFormRef = false">取消</el-button>
          <el-button type="primary" @click="createWelcome">
            {{ welcomeForm.id === null ? '新增' : '确定' }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="commonQuestionListDialog" title="常见问题" width="80%" destroy-on-close align-center
               class="no-padding">
      <template #header="{ close, titleId, titleClass }">
        <div class="clearfix pb20">
          <h4 :id="titleId" :class="titleClass">常见问题</h4>
        </div>
        <div class="clearfix">
          <div class="fl pb5 pr5 pt5">
            <el-input type="text" v-model="commonQuestionFilter.keyword" placeholder="请输入关键词" clearable
                      @keyup.enter="getCommonQuestionList"></el-input>
          </div>
          <div class="fl pb5 pr5 pt5">
            <el-button
              type="primary"
              @click="getCommonQuestionList">
              检索
            </el-button>
          </div>
          <div class="clearfix pb20">
            <div class="fr">
              <el-button type="primary" @click="newCommonQuestion">追加</el-button>
            </div>
          </div>
        </div>
      </template>
      <div class="home-right" v-loading="chatHistoryInfo.loading">
        <Empty v-if="commonQuestionList.length === 0"></Empty>
        <el-table
          :data="commonQuestionList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 600px)"
          v-else>
          <el-table-column
            label="ID"
            prop="id"
            width="80">
          </el-table-column>
          <el-table-column
            prop="question"
            label="问题">
          </el-table-column>
          <el-table-column
            prop="answer"
            label="回答">
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template #default="scope">
              <el-button
                type="primary"
                link
                @click="editCommonQuestion(scope.row)">
                编辑
              </el-button>

              <el-button
                type="danger"
                link
                @click="deleteCommonQuestion(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog v-model="commonQuestionFormRef" :title="commonQuestionForm.id === null ? '新增':'编辑'" width="40%"
               destroy-on-close
               draggable>
      <span v-loading="loading">
        <el-form
          ref="commonQuestionRuleFormRef"
          :model="commonQuestionForm"
          :rules="commonQuestionRules"
          label-width="120px"
          class="home-form"
        >
        <el-form-item label="问题" prop="question">
          <el-input type="textarea" rows="3" v-model="commonQuestionForm.question"></el-input>
        </el-form-item>
        <div v-for="config in merchant.server_languages">
          <el-form-item :label="config.name + '问题'" :prop="config.prefix + '_question'" v-if="config.is_active">
            <el-input type="textarea" rows="3"
                      v-model="commonQuestionForm.multilingual_data[config.prefix + '_question']"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="回答" prop="answer">
           <el-input type="textarea" rows="3" v-model="commonQuestionForm.answer"></el-input>
        </el-form-item>
        <div v-for="config in merchant.server_languages">
          <el-form-item :label="config.name + '回答'" :prop="config.prefix + '_answer'" v-if="config.is_active">
            <el-input type="textarea" rows="3"
                      v-model="commonQuestionForm.multilingual_data[config.prefix + '_answer']"></el-input>
          </el-form-item>
        </div>
        </el-form></span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="commonQuestionFormRef = false">取消</el-button>
        <el-button type="primary" @click="createCommonQuestion">
          {{ commonQuestionForm.id === null ? '新增' : '确定' }}
        </el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="documentPreviewDialog" title="分片预览" width="80%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right" v-loading="loading">
        <Empty v-if="documentPreviewList.length === 0"></Empty>
        <el-table
          :data="documentPreviewList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 300px)"
          v-else>
          <el-table-column
            label="序号"
            type="index"
            width="60">
          </el-table-column>
          <el-table-column
            prop="text"
            label="文本">
            <template #default="scope">
              <p class="preview-cell">{{ scope.row.text }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="clearfix pt5 pb5">
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="multilingualDialog" title="多语言设置" width="40%" destroy-on-close
               draggable align-center>
      <div v-loading="loading" class="p20">
        <el-row v-for="language in merchant.server_languages" class="mt10">
          <el-col :span="8">
            <div> {{ language.name }}</div>
          </el-col>
          <el-col :span="5">
            <el-checkbox :label="language.id" :key="language.id" v-model="language.is_active"
                         :checked="language.is_active" @change="onLanguagesCheckboxChange($event, language)">启用
            </el-checkbox>
          </el-col>
          <el-col :span="5">
            <el-checkbox :label="language.id" :key="language.id" v-model="language.is_published"
                         :checked="language.is_published" :disabled="!language.is_active"
                         @change="onLanguagesPublishedCheckboxChange($event, language)">
              公开
            </el-checkbox>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="checkMerchantMultilingual(language)">公开检查
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div v-if="multilingualForm.messages.length>0" class="p15 mt5" style="background-color: khaki">
        <el-row v-for="message in multilingualForm.messages" class="cRed">
          * {{ message }}
        </el-row>
        <div class="mt10 cRed">由于以上原因,多语言信息无法公开,请补充后重试。</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="multilingualDialog = false">取消</el-button>
          <el-button type="primary" @click="updateMerchantMultilingual">确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { dayjs, ElMessage, ElMessageBox, genFileId } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {

    // merchant form

    const merchantList = ref([])
    const merchant = ref(null)
    const merchantFormRaw = reactive({
      id: null,
      name: null,
      is_active: true,
      streaming: true,
      subtitle: null,
      user_avatar: null,
      user_avatar_url: null,
      assistant_avatar: null,
      assistant_avatar_url: null,
      daily_limit: 100,
      query_limit: 100,
      multilingual_data: {},
    })
    const merchantForm = reactive(JSON.parse(JSON.stringify(merchantFormRaw)))
    const merchantFormRef = ref(false)

    const merchantWelcomeContent = computed(() => {
      return `你好，我是${ merchantForm.name }的小助手`
    })

    const merchantRuleFormRef = ref(null)

    const merchantRulesRaw = reactive({
      name: [ { required: true, message: '请输入设施名称', trigger: 'blur' }, ],
      subtitle: [ { required: true, message: '请输入副标题', trigger: 'blur' }, ],
      daily_limit: [ { required: true, message: '请输入每日访问限制', trigger: 'blur' }, ],
      query_limit: [ { required: true, message: '请输入问题字数限制', trigger: 'blur' }, ],
    })
    const merchantRules = ref({})
    const getMerchantList = () => {
      loading.value = true
      authApi.getMerchantList({ name: searchName.value }).then(res => {
        console.log(res)
        merchantList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }
    const newMerchant = () => {
      Object.keys(merchantFormRaw).forEach(key => {
        merchantForm[key] = merchantFormRaw[key]
      })
      merchant.value = {
        server_languages: configInfo.value.server_languages
      }
      const rawRules = JSON.parse(JSON.stringify(merchantRulesRaw))
      merchantRules.value = {
        ...rawRules,
        'user_avatar': [ { 'required': true, 'message': '请上传用户头像', 'trigger': 'blur' } ],
        'assistant_avatar': [ { 'required': true, 'message': '请上传助手头像', 'trigger': 'blur' } ]
      }
      merchantFormRef.value = true
    }
    const editMerchant = (item) => {
      getMerchantDetail(item).then((newItem) => {
        Object.keys(merchantFormRaw).forEach(key => {
          merchantForm[key] = newItem[key]
        })
        merchantForm.languages = newItem.server_languages.filter(item => item.is_active).map(item => item.id)
        if (newItem.user_avatar !== null) {
          merchantForm.user_avatar_url = `/api/admin/static/avatar/${ newItem.id }/${ newItem.user_avatar }`
        }
        if (newItem.assistant_avatar !== null) {
          merchantForm.assistant_avatar_url = `/api/admin/static/avatar/${ newItem.id }/${ newItem.assistant_avatar }`
        }
        let newMerchantRules = JSON.parse(JSON.stringify(merchantRulesRaw))
        for (let language in newItem.server_languages) {
          const prefix = newItem.server_languages[language].prefix
          newMerchantRules['multilingual_data.' + prefix + '_name'] = [ {
            required: true,
            message: '请输入设施名称',
            trigger: 'blur'
          }, ]
          newMerchantRules['multilingual_data.' + prefix + '_subtitle'] = [ {
            required: true,
            message: '请输入副标题',
            trigger: 'blur'
          }, ]
        }
        merchantRules.value = newMerchantRules

        merchantFormRef.value = true
      })
    }
    const deleteMerchant = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除设施',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        loading.value = true
        authApi.deleteMerchant(item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getMerchantList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createMerchant = () => {
      merchantRuleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          if (merchantForm.id === null) {
            authApi.createMerchant(merchantForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              merchantFormRef.value = false
              getMerchantList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateMerchant(merchantForm.id, merchantForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              merchantFormRef.value = false
              merchant.value = null
              getMerchantList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    const searchName = ref(null)

    const onLanguagesCheckboxChange = (value, language) => {
      if (value) {
        if (multilingualForm.languages.includes(language.id)) {
          return
        }
        multilingualForm.languages.push(language.id)
      } else {
        multilingualForm.languages = multilingualForm.languages.filter(item => item !== language.id)
      }
    }

    const onLanguagesPublishedCheckboxChange = (value, language) => {
      if (value) {
        if (multilingualForm.published.includes(language.id)) {
          return
        }
        ElMessageBox.confirm(
          '确定要公开吗?',
          '多语言公开',
          {
            confirmButtonText: '公开',
            cancelButtonText: '取消',
            type: 'error',
          }).then(() => {
          const form = {
            languages: [ language.id ],
            published: [ language.id ],
          }
          authApi.checkMerchantMultilingual(merchant.value.id, form).then(res => {
            multilingualForm.messages = res.data
            if (res.data.length === 0) {
              multilingualForm.published.push(language.id)
            } else {
              language.is_published = !language.is_published
            }
          }).finally(() => {
            loading.value = false
          })
        }).catch(() => {
          language.is_published = !language.is_published
        })

      } else {
        multilingualForm.published = multilingualForm.published.filter(item => item !== language.id)
      }
    }

    const getMerchantDetail = (item, force = false) => {
      return new Promise((resolve, reject) => {
        authApi.getMerchantDetail(item.id).then(res => {
          console.log(res.data)
          merchant.value = res.data
          resolve(merchant.value)
        }).catch(err => {
          reject(err)
        })
      })
    }

    // 文件 form

    const formRaw = reactive({
      merchant_id: null,
      content: null,
      prompt: null,
      chunk_size: null,
      es_stop_words: null,
      multilingual_data: {},
    })

    const form = reactive(JSON.parse(JSON.stringify(formRaw)))
    const loading = ref(false)
    const rulesRaw = reactive({
      merchant_id: [ { required: true, message: '请选择设施', trigger: 'blur' }, ],
      content: [ { required: true, message: '请填写文本', trigger: 'blur' }, ]
    })
    const rules = ref({})
    const ruleFormRef = ref(null)
    const upload = ref(null)
    const handleExceed = (files) => {
      upload.value.clearFiles()
      const file = files[0]
      file.uid = genFileId()
      upload.value.handleStart(file)
    }
    const handleChange = (file, fileList) => {
      console.log(file)
      form.file = file.raw
    }
    const submit = () => {
      ruleFormRef.value.validate((valid, fields) => {
        if (valid) {
          loading.value = true
          console.log(form)
          authApi.upload(form).then(res => {
            console.log(res)
            ElMessage({
              message: '上传成功',
              type: 'success',
            })
            getMerchantDetail(merchant.value, true)
            documentDialog.value = false
          }).finally(() => {
            loading.value = false
          })
        } else {
          let active = 'cn'
          for (let item in fields) {
            if (item !== 'content' && item.includes('content')) {
              const match = item.match(/\.(\w+)_content/);
              if (match) {
                active = match[1]
                break
              }
            } else
              break
          }
          if (activeName.value !== active) {
            activeName.value = active
          }
        }
      })
    }

    const downloadDocument = () => {
      authApi.downloadDocument(merchant.value.id, merchant.value.document.id).then(res => {
        download(res)
      })

    }

    const documentDialog = ref(false)
    const openDocumentDialog = (item) => {
      getMerchantDetail(item).then((newItem) => {
        const document = newItem.document
        Object.keys(formRaw).forEach(key => {
          form[key] = document ? document[key] : formRaw[key]
        })
        form.merchant_id = newItem.id
        let newRules = JSON.parse(JSON.stringify(rulesRaw))
        for (let language in newItem.server_languages) {
          const prefix = newItem.server_languages[language].prefix
          newRules['multilingual_data.' + prefix + '_content'] = [ {
            required: true,
            message: '请填写文本',
            trigger: 'blur'
          }, ]
        }
        rules.value = newRules
        activeName.value = 'cn'
        documentDialog.value = true
      })
    }
    const downloadRef = ref(null)
    const download = (res) => {
      let fileName = ''
      const disposition = res.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        // 正規表現
        const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          // matches[1]でとれる⇒ filename*=utf-8''201911%E3%83%87%E3%83%BC%E3%82%BF.csv;
          // 不要文字列を消して、デコードしてサーバからのファイル名を取得
          fileName = decodeURI(
            matches[1].replace(/['"]/g, '').replace('utf-8', '')
          )
        }
      }
      const fileURL = window.URL.createObjectURL(
        new Blob([ res.data ], { type: 'application/octet-stream' })
      )
      console.log(fileURL)
      downloadRef.value.href = fileURL
      downloadRef.value.setAttribute('download', fileName)
      downloadRef.value.click()
      window.URL.revokeObjectURL(fileURL)
    }

    const configInfo = computed(() => {
      return store.getters.config
    })
    const activeName = ref('cn')

    const handleClick = (tab, event) => {
      console.log(tab, event)
    }
    // document preview
    const documentPreviewDialog = ref(false)
    const documentPreviewList = ref([])
    const documentPreview = () => {
      loading.value = true
      const data = JSON.parse(JSON.stringify(form))
      let fullField = 'content'
      if (activeName.value !== 'cn') {
        fullField = activeName.value + '_content'
        data.content = data['multilingual_data'][fullField]
      } else {
        data.content = data[fullField]
      }

      if (data.content === null || data.content === '' || data.content === undefined) {
        ElMessage({
          message: '请输入文档',
          type: 'error',
        })
        loading.value = false
        return
      }
      authApi.uploadPreview(data).then(res => {
        documentPreviewList.value = res.data
        documentPreviewDialog.value = true
      }).finally(() => {
        loading.value = false
      })
    }
    // 会话记录
    const chatHistoryFilterRaw = reactive({
      user_id: null,
      page: 1,
      size: 10,
      keyword: null,
      totalSize: 0,
    })
    const chatHistoryFilter = reactive(JSON.parse(JSON.stringify(chatHistoryFilterRaw)))
    const chatHistoryInfo = reactive({
      totalSize: 0,
      loading: false,
    })
    const handleChatUserChange = (file, fileList) => {
      if (file === '') {
        chatHistoryFilter.user_id = null
      }
      getChatHistory()
    }
    const identifierList = ref([])
    const chatHistory = ref([])
    const chatHistoryDialog = ref(false)
    const openChatHistoryDialog = (item) => {
      Object.keys(chatHistoryFilterRaw).forEach(key => {
        chatHistoryFilter[key] = chatHistoryFilterRaw[key]
      })
      merchant.value = item
      getChatHistory()
      chatHistoryDialog.value = true
    }

    const getChatHistory = (reset) => {
      if (reset === true) {
        chatHistoryFilter.page = 1
      }
      // chatHistory.value = []
      chatHistoryInfo.loading = true
      authApi.getChatHistory(merchant.value.id, chatHistoryFilter).then(res => {
        chatHistory.value = res.data.list
        chatHistoryFilter.totalSize = res.data.total
        identifierList.value = res.data.user_list.map(item => {
          return {
            value: item.id,
            label: item.identifier,
          }
        })
      }).finally(() => {
        chatHistoryInfo.loading = false
      })
    }
    const dateFormat = (row, column, cellValue) => {
      return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
    }
    const downloadChatHistory = () => {
      authApi.downloadChatHistory(merchant.value.id, chatHistoryFilter).then(res => {
        download(res)
      })
    }

    // welcome
    const welcomeList = ref([])
    const welcomeListDialog = ref(false)
    const openWelcomeListDialog = (item) => {
      getMerchantDetail(item).then((newItem) => {
        getWelcomeList()
        welcomeListDialog.value = true
      })
    }
    const getWelcomeList = (reset) => {
      // chatHistory.value = []
      chatHistoryInfo.loading = true
      authApi.getWelcomeList(merchant.value.id).then(res => {
        welcomeList.value = res.data
      }).finally(() => {
        chatHistoryInfo.loading = false
      })
    }

    const welcomeFormRaw = reactive({
      id: null,
      message: null,
      start_date: new Date(),
      end_date: null,
      is_default: false,
      multilingual_data: {},
    })
    const welcomeForm = reactive(JSON.parse(JSON.stringify(welcomeFormRaw)))
    const welcomeFormRef = ref(false)

    const welcomeRuleFormRef = ref(null)

    const welcomeRules = reactive({
      message: [
        { required: true, message: '请输入欢迎信息', trigger: 'blur' },
      ],
      start_date: [
        { required: true, message: '请选择开始日期', trigger: 'blur' },
      ],
      end_date: [
        { required: true, message: '请选择结束日期', trigger: 'blur' },
      ],
    })

    const newWelcome = () => {
      Object.keys(welcomeFormRaw).forEach(key => {
        welcomeForm[key] = welcomeFormRaw[key]
      })
      welcomeFormRef.value = true
    }
    const editWelcome = (item) => {
      console.log(item)
      Object.keys(welcomeFormRaw).forEach(key => {
        welcomeForm[key] = item[key]
      })
      welcomeForm.message = item.message
      welcomeFormRef.value = true
    }
    const deleteWelcome = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除欢迎信息',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteWelcome(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getWelcomeList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createWelcome = () => {
      welcomeRuleFormRef.value.validate((valid) => {
        if (valid) {
          let verify_welcome = welcomeList.value.find(item => {
            const result = item.end_date >= welcomeForm.start_date && item.start_date <= welcomeForm.end_date && item.is_default === false;
            return welcomeForm.id !== null ? (result && item.id !== welcomeForm.id) : result;
          })
          if (verify_welcome !== undefined && welcomeForm.is_default === false) {
            ElMessage({
              message: '该时间段已存在欢迎信息',
              type: 'error',
            })
            return
          }

          loading.value = true
          if (welcomeForm.id === null) {
            authApi.createWelcome(merchant.value.id, welcomeForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              welcomeFormRef.value = false
              getWelcomeList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateWelcome(merchant.value.id, welcomeForm.id, welcomeForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              welcomeFormRef.value = false
              getWelcomeList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    const userAvatarHandleChange = (file, fileList) => {
      if (file === '') {
        merchantForm.user_avatar = null
      }
      console.log(file)
      const userReader = new FileReader();

      userReader.onload = () => {
        merchantForm.user_avatar = userReader.result;
        merchantForm.user_avatar_url = userReader.result;
      };
      userReader.readAsDataURL(file.raw);
    }

    const assistantAvatarHandleChange = (file, fileList) => {
      if (file === '') {
        merchantForm.assistant_avatar = null
      }
      console.log(file)
      const assistantReader = new FileReader();
      assistantReader.readAsDataURL(file.raw);
      assistantReader.onload = () => {
        merchantForm.assistant_avatar = assistantReader.result;
        merchantForm.assistant_avatar_url = assistantReader.result;
      };
      assistantReader.readAsDataURL(file.raw);
    }

    // common question
    const commonQuestionList = ref([])
    const commonQuestionListDialog = ref(false)
    const openCommonQuestionListDialog = (item) => {
      getMerchantDetail(item).then((newItem) => {
        getCommonQuestionList()
        commonQuestionListDialog.value = true
      })
    }

    const getCommonQuestionList = (reset) => {
      // chatHistory.value = []
      chatHistoryInfo.loading = true
      authApi.getCommonQuestionList(merchant.value.id, commonQuestionFilter).then(res => {
        commonQuestionList.value = res.data
      }).finally(() => {
        chatHistoryInfo.loading = false
      })
    }

    const commonQuestionFormRaw = reactive({
      id: null,
      question: null,
      answer: null,
      multilingual_data: {},
    })
    const commonQuestionForm = reactive(JSON.parse(JSON.stringify(commonQuestionFormRaw)))
    const commonQuestionFormRef = ref(false)

    const commonQuestionRuleFormRef = ref(null)

    const commonQuestionRules = reactive({
      question: [
        { required: true, message: '请输入问题', trigger: 'blur' },
      ],
      answer: [
        { required: true, message: '请输入答案', trigger: 'blur' },
      ],
    })

    const commonQuestionFilter = reactive({
      keyword: null,
    })

    const newCommonQuestion = () => {
      Object.keys(commonQuestionFormRaw).forEach(key => {
        commonQuestionForm[key] = commonQuestionFormRaw[key]
      })
      commonQuestionFormRef.value = true
    }
    const editCommonQuestion = (item) => {
      console.log(item)
      Object.keys(commonQuestionFormRaw).forEach(key => {
        commonQuestionForm[key] = item[key]
      })
      commonQuestionFormRef.value = true
    }
    const deleteCommonQuestion = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除常见问题',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteCommonQuestion(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getCommonQuestionList()
        }).finally(() => {
          loading.value = false
        })
      })

    }
    const createCommonQuestion = () => {
      commonQuestionRuleFormRef.value.validate((valid) => {
        if (valid) {

          loading.value = true
          if (commonQuestionForm.id === null) {
            authApi.createCommonQuestion(merchant.value.id, commonQuestionForm).then(res => {
              console.log(res)
              ElMessage({
                message: '创建成功',
                type: 'success',
              })
              commonQuestionFormRef.value = false
              getCommonQuestionList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.updateCommonQuestion(merchant.value.id, commonQuestionForm.id, commonQuestionForm).then(res => {
              console.log(res)
              ElMessage({
                message: '编辑成功',
                type: 'success',
              })
              commonQuestionFormRef.value = false
              getCommonQuestionList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }

    // 多语言
    const serverLanguages = computed(() => {
      return store.getters.languages
    })

    const multilingualDialog = ref(false)

    const openMultilingualDialog = (item) => {
      getMerchantDetail(item).then((newItem) => {
        multilingualForm.languages = newItem.server_languages.filter(item => item.is_active).map(item => item.id)
        multilingualForm.published = newItem.server_languages.filter(item => item.is_published).map(item => item.id)
        multilingualForm.messages = []
        multilingualDialog.value = true
      })
    }

    const multilingualForm = reactive({
      languages: [],
      published: [],
      messages: [],
    })

    const multilingualRuleFormRef = ref(null)

    const updateMerchantMultilingual = () => {
      authApi.updateMerchantMultilingual(merchant.value.id, multilingualForm).then(res => {
        console.log(res)
        ElMessage({
          message: '编辑成功',
          type: 'success',
        })
        multilingualDialog.value = false
        getMerchantList()
      }).finally(() => {
        loading.value = false
      })
    }

    const checkMerchantMultilingual = (language) => {
      const form = {
        languages: [ language.id ],
        published: [ language.id ],
      }
      authApi.checkMerchantMultilingual(merchant.value.id, form).then(res => {
        console.log(res)
        multilingualForm.messages = res.data
        if (res.data.length === 0) {
          ElMessage.success('检查通过,可以公开.')
        }
      }).finally(() => {
        loading.value = false
      })
    }

    onMounted(() => {
      getMerchantList()
    })
    return {
      form,
      loading,
      rules,
      ruleFormRef,
      upload,
      handleExceed,
      submit,
      handleChange,
      merchantList,
      merchant,
      merchantForm,
      merchantFormRef,
      newMerchant,
      createMerchant,
      merchantRuleFormRef,
      merchantRules,
      downloadDocument,
      editMerchant,
      deleteMerchant,
      documentDialog,
      openDocumentDialog,
      downloadRef,
      searchName,
      getMerchantList,
      configInfo,
      chatHistory,
      chatHistoryDialog,
      openChatHistoryDialog,
      chatHistoryFilter,
      handleChatUserChange,
      identifierList,
      getChatHistory,
      chatHistoryInfo,
      dateFormat,
      downloadChatHistory,
      merchantWelcomeContent,
      welcomeList,
      welcomeListDialog,
      openWelcomeListDialog,
      newWelcome,
      createWelcome,
      welcomeForm,
      welcomeFormRef,
      welcomeRuleFormRef,
      welcomeRules,
      editWelcome,
      deleteWelcome,
      userAvatarHandleChange,
      assistantAvatarHandleChange,
      commonQuestionList,
      commonQuestionListDialog,
      openCommonQuestionListDialog,
      newCommonQuestion,
      createCommonQuestion,
      commonQuestionForm,
      commonQuestionFormRef,
      commonQuestionRuleFormRef,
      commonQuestionRules,
      commonQuestionFilter,
      getCommonQuestionList,
      editCommonQuestion,
      deleteCommonQuestion,

      documentPreviewDialog,
      documentPreviewList,
      documentPreview,
      activeName,
      handleClick,
      onLanguagesCheckboxChange,

      // 多语言
      serverLanguages,
      openMultilingualDialog,
      multilingualDialog,
      multilingualForm,
      multilingualRuleFormRef,
      updateMerchantMultilingual,
      onLanguagesPublishedCheckboxChange,
      checkMerchantMultilingual,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
